<template>
  <div class='JZContainer'>
    <el-form class="content_block">
      <el-row>
        <el-col :span="6">
          <el-form-item label="发票号：">发票号</el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="发票金额：">发票金额</el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="数量合计：">数量合计</el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="开票日期：">开票日期</el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="税率：">税率</el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="净额：">净额</el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="税额：">税额</el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="录入人：">录入人</el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="录入时间：">录入时间</el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="发票状态：">发票状态</el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <TableWithPagination
        :tableData="targetLists"
        ref="orderTable"
        class="content_block"
        :columnList="columnList"
        :pagination.sync="pagination"
        :sortInfo.sync="updateSortInfo"
        @current-change="getTargetLists"
        @sort-change="getTargetLists"
      ></TableWithPagination>
  </div>
</template>

<script>
import InfoMixin from '@/mixins/infoMixin.js';
export default {
  name: 'SalesInvoicingDetail',
  mixins: [InfoMixin],
  methods: {
    getTargetLists () {
      // let currentParams = this.getCurrentParams();
      // SystemSetting.getAccountLists(currentParams).then(this.getTargetListsSuccessFun);
    },
  },
  data () {
    return {
      targetLists: [{id: 1}],
      columnList: [
        {
          tooltip: true,
          label: '客户抬头',
          minWidth: '110px',
          prop: '',
        },
        {
          tooltip: true,
          label: '商品名称',
          minWidth: '150px',
          prop: 'ledgerType',
        },
        {
          tooltip: true,
          label: '计量单位',
          minWidth: '110px',
          prop: 'expiryTime',
        },
        {
          tooltip: true,
          label: '开票数量',
          minWidth: '110px',
          prop: 'expiryTime',
        },
        {
          tooltip: true,
          label: '含税单价',
          minWidth: '110px',
          prop: 'expiryTime',
        },
        {
          tooltip: true,
          label: '无税单价',
          minWidth: '110px',
          prop: 'expiryTime',
        },
        {
          tooltip: true,
          label: '开票净额',
          minWidth: '110px',
          prop: 'expiryTime',
        },
        {
          tooltip: true,
          label: '税额',
          minWidth: '110px',
          prop: 'expiryTime',
        },
        {
          tooltip: true,
          label: '含税金额',
          minWidth: '110px',
          prop: 'expiryTime',
        },
      ],
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */

</style>
